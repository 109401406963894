import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { format, addMonths } from 'date-fns';
import { CaretLeft, CaretRight, Sliders, X } from 'phosphor-react';
import ReactPaginate from 'react-paginate';
import uniqBy from 'lodash/uniqBy';
import { keyframes } from '@emotion/react';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';
import { defaultProducts } from './defaultProducts';

import MapResults from './MapResults';
import InformationalBanner from '../../components/common/InformationalBanner';
import InlineFilters from './filters/InlineFilters';
import LoadingDots from '../../components/common/LoadingDots';
import MapCardItem from './MapCardItem';
import ResultItem from './ResultItemNew';
import ApplicationsSection from './applications/ApplicationsSection';
import HomeHeader from '../../components/site-header/HomeHeader';
import FiltersContainer from './filters/FiltersContainer';
import ViewToggleButtons from './ViewToggleButtons';
import ProductPreview from './product/ProductPreview';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${(props) => (props.isClosing ? fadeOut : fadeIn)} 0.3s ease-in-out;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1001;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #e4eef6;
`;

const MarginContainer = styled.div`
  margin-top: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 60px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px;
  width: 100%;

  @media (min-width: ${screenSizes.small.min}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${screenSizes.medium.min}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${screenSizes.large.min}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const FilterContainer = styled.div`
  position: absolute;
  top: 65vh;
  width: 100%;
  height: 90px;
  background: rgba(255, 255, 255, 0);
  padding: 10px;
  box-shadow: none;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${screenSizes.medium.min}) {
    top: 65vh;
    width: 95%;
    margin-inline: 30px;
    background: rgba(228, 238, 246, 0.6);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const MobileFilterButton = styled.div`
  position: relative;
  bottom: 40px;
  left: 50%;
  width: 120px;
  transform: translateX(-50%);
  background-color: ${colors.BLUE};
  color: white;
  padding: 12px 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;

  @media (min-width: ${screenSizes.small.max}) {
    display: none !important;
  }
`;

const FilterButtonText = styled.span`
  margin-left: 10px;
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 0 40px;
  }
`;

const NoResultsLabel = styled.h1`
  text-align: center;
`;

const StyledPagination = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;

  li {
    margin: 0 5px;
  }

  li a {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5); // Couleur par défaut pour les chiffres inactifs
    padding: 5px 10px;
    border-radius: 3px;
    transition: color 0.3s ease, transform 0.3s ease;
    font-weight: 500;
    font-size: 14px;
  }

  li:not(.disabled) a:hover {
    color: rgba(0, 0, 0, 0.8);
    transform: scale(1.05);
  }

  li.selected a {
    color: rgba(0, 0, 0, 0.9); // Couleur presque noire pour le chiffre actif
    font-weight: 700;
    font-size: 16px;
    transform: scale(1.1);
  }

  li.disabled a {
    color: rgba(0, 0, 0, 0.2); // Couleur très transparente pour les éléments désactivés
    cursor: not-allowed;
  }

  li.previous a,
  li.next a {
    font-size: 18px;
    padding: 5px;
    color: rgba(0, 0, 0, 0.7); // Couleur pour les flèches actives
  }

  li.previous.disabled a,
  li.next.disabled a {
    color: rgba(0, 0, 0, 0.2); // Couleur pour les flèches désactivées
  }

  li.previous:not(.disabled) a:hover,
  li.next:not(.disabled) a:hover {
    color: rgba(0, 0, 0, 1); // Noir plein au survol des flèches
    transform: scale(1.1);
  }
`;

const ProductNew = () => {
  const [previewProductId, setPreviewProductId] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const listResults = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [view, setView] = useState('grid');
  const [selectedFilters, setSelectedFilters] = useState({
    date_from: format(addMonths(new Date(), -6), 'yyyy-MM-dd'),
    date_to: format(new Date(), 'yyyy-MM-dd'),
    is_charter: null,
  });

  const PER_PAGE = 3;
  const offset = currentPage * PER_PAGE;

  useEffect(() => {
    const IS_LOCALHOST = window.location.hostname === 'localhost';

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('/our_products');
        setData(response?.data);
        setProducts(response?.data.products);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (IS_LOCALHOST) {
          // console.log('window.location.hostname', window.location.hostname);
          setData(defaultProducts);
          setProducts(defaultProducts.products);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getUniqActivations = () => {
    if (!products) return [];
    const activations = products
      ?.map(({ map_event, area_event_name }) => ({
        activationName: map_event.glide,
        countryName: area_event_name,
      }))
      .filter((activation) => activation.activationName);
    return uniqBy(activations, 'activationName');
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closePreview();
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    listResults.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleProductClick = (productId) => {
    setPreviewProductId(productId);
    setIsClosing(false);
  };

  const closePreview = () => {
    setIsClosing(true);
    setTimeout(() => {
      setPreviewProductId(null);
      setIsClosing(false);
    }, 300); // This should match the animation duration
  };

  const renderResults = (activation) => {
    const productResults = products?.filter(
      ({ map_event }) => map_event.glide === activation.activationName,
    );

    if (view === 'grid') {
      return productResults.map((product, index) => (
        <MapCardItem
          key={`product-${activation.activationName}-${index}`}
          data={product}
          mapData={product?.map_event}
        />
      ));
    } else {
      return productResults.map((product, index) => (
        <ResultItem
          key={`product-${activation.activationName}-${index}`}
          data={product}
          mapData={product?.map_event}
          onClick={handleProductClick}
        />
      ));
    }
  };

  const currentPageData = getUniqActivations()
    ?.slice(offset, offset + PER_PAGE)
    .map(renderResults);

  const pageCount = Math.ceil(getUniqActivations()?.length / PER_PAGE);

  return (
    <MainContainer>
      <HomeHeader />
      <MapResults products={products} />
      <FilterContainer>
        <InlineFilters
          countries={data?.countries}
          activationTypes={data?.activation_types}
          initialFilters={selectedFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          products={products}
          setProducts={setProducts}
          setIsLoading={setIsLoading}
          setCurrentPage={setCurrentPage}
        />
      </FilterContainer>
      <MobileFilterButton
        onClick={() => setIsMobileFilterOpen(true)}
        style={{ display: isMobileFilterOpen ? 'none' : 'block' }}
      >
        <Sliders size={22} color="white" />
        <FilterButtonText>Filters</FilterButtonText>
      </MobileFilterButton>
      <FiltersContainer
        isModalOpen={isMobileFilterOpen}
        handleCloseModal={() => setIsMobileFilterOpen(false)}
        countries={data?.countries}
        activationTypes={data?.activation_types}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        setProducts={setProducts}
        setIsLoading={setIsLoading}
        setCurrentPage={setCurrentPage}
      />
      <InformationalBanner color={colors.RED} />
      <MarginContainer>
        <ViewToggleButtons view={view} setView={setView} />
        {isLoading ? (
          <LoadingDots />
        ) : (
          products && (
            <Wrapper id="list-results" ref={listResults}>
              {products.length > 0 ? (
                <>
                  {view === 'grid' ? (
                    <GridContainer>{currentPageData}</GridContainer>
                  ) : (
                    <div>{currentPageData}</div>
                  )}
                  <StyledPagination
                    previousLabel={<CaretLeft size={20} />}
                    nextLabel={<CaretRight size={20} />}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'previous'}
                    nextClassName={'next'}
                    breakClassName={'break-item'}
                    activeClassName={'selected'}
                    disabledClassName={'disabled'}
                  />
                </>
              ) : (
                <NoResultsLabel>No results</NoResultsLabel>
              )}
            </Wrapper>
          )
        )}
        <ApplicationsSection />
      </MarginContainer>
      {previewProductId && (
        <>
          <CloseButton onClick={closePreview}>
            <X size={24} />
          </CloseButton>
          <ModalOverlay onClick={handleOverlayClick} isClosing={isClosing}>
            <ProductPreview productId={previewProductId} onClose={closePreview} />
          </ModalOverlay>
        </>
      )}
    </MainContainer>
  );
};

export default ProductNew;
