// LinkButtonsPreview.jsx - A simplified version for the results list
import React, { useState } from 'react';
import { DownloadSimple } from 'phosphor-react';
import styled from '@emotion/styled';
import axios from 'axios';

const DownloadItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DownloadItem = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 0px 8px;
  background-color: #ffffff;
  text-align: left;
  color: #617077;
  margin-bottom: 8px;
  width: fit-content;
  border-radius: 30px;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #617077;
  }

  &:hover a,
  &:hover div {
    color: #ffffff;
  }
`;

const DownloadLink = styled.a`
  color: rgba(82, 94, 104, 0.5);
  text-decoration: none;
  font-weight: 700;
  margin: 0 8px;
  align-items: center;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  transition: color 0.3s ease;
`;

const IconWrapper = styled.div`
  color: rgba(82, 94, 104, 0.5);
  margin-right: 8px;
  transition: color 0.3s ease;
`;

const IS_LOCALHOST = window.location.hostname === 'localhost';

// This version of LinkButtons works with the product ID and lazy loads the data
const LinkButtonsPreview = ({ id }) => {
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProductDetails = async () => {
    if (productData || isLoading) return;

    setIsLoading(true);
    try {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
      };

      const response = await axios.get(`/our_products/${id}`, { headers });
      setProductData(response?.data?.map_event);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setError(error);
      setIsLoading(false);

      // If we're on localhost, set some dummy data for testing
      if (IS_LOCALHOST) {
        setProductData({
          title: 'Example Product',
          pdf_name: 'example.pdf',
          excel_table: '/example/path',
          wmap_link: 'https://example.com/map',
          gdp_link: '/example/gdp',
          shp_link: '/example/shp',
          kml_link: '/example/kml',
          wms_link: '/example/wms',
        });
      }
    }
  };

  const renderDownloadItem = (label, href, download) => (
    <DownloadItem>
      <IconWrapper>
        <DownloadSimple size={20} />
      </IconWrapper>
      <DownloadLink href={href} download={download} target="_blank">
        {label}
      </DownloadLink>
    </DownloadItem>
  );

  // If we have product data, show the full set of download options
  if (productData) {
    const { title, pdf_name, excel_table, wmap_link, gdp_link, shp_link, kml_link, wms_link } =
      productData;

    return (
      <DownloadItemsWrapper>
        {(IS_LOCALHOST || pdf_name) &&
          pdf_name !== 'None' &&
          renderDownloadItem('PDF', `/static/unosat_filesystem/${id}/${pdf_name}`, pdf_name)}
        {(IS_LOCALHOST || shp_link) && renderDownloadItem('SHAPEFILE', shp_link, shp_link)}
        {(IS_LOCALHOST || gdp_link) && renderDownloadItem('GEODATABASE', gdp_link, gdp_link)}
        {(IS_LOCALHOST || excel_table) &&
          !excel_table?.includes('/None') &&
          renderDownloadItem('TABLE', `/static/${excel_table}`, title)}
        {(IS_LOCALHOST || wmap_link) && renderDownloadItem('WEBMAP', wmap_link)}
        {(IS_LOCALHOST || wms_link) && renderDownloadItem('WMS', wms_link)}
        {(IS_LOCALHOST || kml_link) && renderDownloadItem('KML', kml_link, kml_link)}
      </DownloadItemsWrapper>
    );
  }

  // If no data yet, show a simplified version with a download option that triggers the data load
  return (
    <DownloadItemsWrapper onMouseEnter={fetchProductDetails}>
      <DownloadItem onClick={fetchProductDetails}>
        <IconWrapper>
          <DownloadSimple size={20} />
        </IconWrapper>
        <DownloadLink>{isLoading ? 'Loading...' : error ? 'Error' : 'DOWNLOAD'}</DownloadLink>
      </DownloadItem>
    </DownloadItemsWrapper>
  );
};

export default LinkButtonsPreview;
