import React from 'react';
import { DownloadSimple } from 'phosphor-react';
import styled from '@emotion/styled';

const DownloadItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DownloadItem = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 0px 8px;
  background-color: #ffffff;
  text-align: left;
  color: #617077;
  margin-bottom: 8px;
  width: fit-content;
  border-radius: 30px;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #617077;
  }

  &:hover a,
  &:hover div {
    color: #ffffff;
  }
`;

const DownloadLink = styled.a`
  color: rgba(82, 94, 104, 0.5);
  text-decoration: none;
  font-weight: 700;
  margin: 0 8px;
  align-items: center;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  transition: color 0.3s ease;
`;

const IconWrapper = styled.div`
  color: rgba(82, 94, 104, 0.5);
  margin-right: 8px;
  transition: color 0.3s ease;
`;

const IS_LOCALHOST = window.location.hostname === 'localhost';

const LinkButtons = ({ title, pdfName, excel, wmap, gdp, shp, kml, wms, id }) => {
  console.log('LinkButtons', title, pdfName, excel, wmap, gdp, shp, kml, wms, id);
  const renderDownloadItem = (label, href, download) => (
    <DownloadItem>
      <IconWrapper>
        <DownloadSimple size={20} />
      </IconWrapper>
      <DownloadLink href={href} download={download} target="_blank">
        {label}
      </DownloadLink>
    </DownloadItem>
  );

  return (
    <DownloadItemsWrapper>
      {(IS_LOCALHOST || pdfName) &&
        renderDownloadItem('PDF', `/static/unosat_filesystem/${id}/${pdfName}`, pdfName)}
      {(IS_LOCALHOST || shp) && renderDownloadItem('SHAPEFILE', shp, shp)}
      {(IS_LOCALHOST || gdp) && renderDownloadItem('GEODATABASE', gdp, gdp)}
      {(IS_LOCALHOST || excel) && renderDownloadItem('TABLE', `/static/${excel}`, title)}
      {(IS_LOCALHOST || wmap) && renderDownloadItem('WEBMAP', wmap)}
      {(IS_LOCALHOST || wms) && renderDownloadItem('WMS', wms)}
      {(IS_LOCALHOST || kml) && renderDownloadItem('KML', kml, kml)}
    </DownloadItemsWrapper>
  );
};

export default LinkButtons;
