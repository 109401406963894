import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import axios from 'axios';
import { colors } from '../../constants/colors';
import { urls } from '../../constants/urls';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const defaultNewsAndStories = [
  {
    title: 'Geospatial Decision Support for Climate Resilience in the Pacific',
    description:
      'A series of tailored training was delivered by UNOSAT experts in November and December 2022 in 3 countries of the Pacific: Vanuatu, the Solomon Islands and Fiji. Building up on past activities, these sessions aimed to enhance GIS capacity for Climate Resilience.',
    image: '/static/unosat_filesystem/news_stories/GDS4CR_SolomonIslands_27112022_72.jpg',
    link: 'https://unitar.org/about/news-stories/news/geospatial-decision-support-climate-resilience-pacific',
    category: 'News',
  },
  {
    title: 'Partnership between CBAS & UNOSAT',
    description:
      '27 June 2023, Beijing, China - The United Nations Institute for Training and Research (UNITAR) and The International Research Center of Big Data for Sustainable Development Goals (CBAS) are pleased to announce the signing of a Memorandum of Understanding (MoU).  The partnership between CBAS and UNOSAT comes at a crucial time when the world faces complex challenges that require innovative and data-driven solutions.',
    image: '/static/unosat_filesystem/news_stories/2_62.png',
    link: 'https://unitar.org/about/news-stories/featuredarticles/unitar-and-cbas-sign-memorandum-understanding',
    category: 'Stories',
  },
  {
    title:
      'Midline Event Workshop, Strengthening capacities in the use of GIT for improved resilience in Asia, Pacific & Africa',
    description:
      'UNOSAT organised the Midline Event workshop in June 2023 at Bangkok, Thailand for all the experts and stakeholders from its project partner countries . This event steered at focusing and addressing pertinent issues faced by the partner countries.',
    image: '/static/unosat_filesystem/news_stories/Picture1_49.jpg',
    link: 'https://unitar.org/about/news-stories/news/working-high-risks-countries-building-climate-resilience-through-geospatial-technology-asia-pacific',
    category: 'News',
  },
  {
    title:
      'Participation to Partnership: Recognising the Significance of Cooperation & Collaboration for Robust Capacity Development',
    description:
      'In November 2023 The United Nations Satellite Centre (UNOSAT) at Vanuatu in partnership with VanKIRAP, the Secretariat of the Pacific Regional Environment Programme (SPREP) extended the delivery of technical training in geospatial information technology at the subnational level.',
    image: '/static/unosat_filesystem/news_stories/Picture1%20vanuatu_0.png',
    link: 'https://www.unitar.org/about/news-stories/featuredarticles/participation-partnership-recognising-significance-cooperation-and-collaboration-robust-capacity',
    category: 'News',
  },
  {
    title: 'ADVANCED REMOTE SENSING FOR SUSTAINABLE LAND MANAGEMENT',
    description:
      '"These systems will really enhance and open more gateways for non-technological people who would appreciate the technology and use it better." Survey Engineer Mr Chokila from the National Land Commission Secretariat (NLCS)',
    image: '/static/unosat_filesystem/news_stories/remote%20sensing.png',
    link: 'https://unitar.org/about/news-stories/news/advanced-remote-sensing-sustainable-land-management',
    category: 'News',
  },
  {
    title:
      'UNESCO AND UN SATELLITE CENTRE JOIN FORCES TO SAFEGUARD UKRAINE’S CULTURAL HERITAGE WITH GEOSPATIAL TECHNOLOGIES',
    description:
      'From 27 to 30 November 2023, UNESCO and UNOSAT staff conducted training for twenty Ukrainian cultural professionals in Lviv, Ukraine. Read more!',
    image:
      '/static/unosat_filesystem/news_stories/Capture%20d%E2%80%99e%CC%81cran%202023-12-04%20a%CC%80%2016.53.23_0.png',
    link: 'https://unitar.org/about/news-stories/news/unesco-and-un-satellite-centre-join-forces-safeguard-ukraines-cultural-heritage-geospatial',
    category: 'News',
  },
  {
    title: "EMPOWERING WOMEN IN SCIENCE: UNOSAT'S IMPACTFUL WORK IN GEOSPATIAL ANALYSIS",
    description:
      'UNOSAT, with women like Michelle de Gruchy, Katarina Palmkron, and Joy Papao leading efforts, exemplifies progress in leveraging satellite imagery and geospatial analysis for addressing global challenges. Their passion, resilience, and leadership underscore the importance of gender equality and empowerment in science and technology.',
    image: '/static/unosat_filesystem/news_stories/Katinka.jpg',
    link: 'https://unitar.org/about/news-stories/news/empowering-women-science-unosats-impactful-work-geospatial-analysis',
    category: 'News',
  },
  {
    title: 'EMPOWERING DISASTER RESILIENCE IN BANGLADESH: GEOSPATIAL TRAINING FOR PROGRESS',
    description:
      'In Dhaka, Bangladesh, strengthening GIS capacities is crucial for disaster resilience. A recent NORAD and UNOSAT-supported training program focused on enhancing disaster management skills through QGIS software, resulting in increased competence and highlighting the importance of geospatial data for risk management.',
    image:
      '/static/unosat_filesystem/news_stories/Capture%20d%E2%80%99e%CC%81cran%202024-01-18%20a%CC%80%2010.56.36_0.png',
    link: 'https://unitar.org/about/news-stories/news/empowering-disaster-resilience-bangladesh-geospatial-training-progress',
    category: 'News',
  },
  {
    title: "UNOSAT'S EMPOWERMENT IN DISASTER RISK MANAGEMENT",
    description:
      "In Vientiane, Lao PDR, a NORAD-funded training program by UNOSAT focused on geospatial technologies for disaster risk management. The program boosted participants' proficiency and confidence levels in Earth observation and remote sensing techniques, aligning effectively with training objectives.",
    image:
      '/static/unosat_filesystem/news_stories/Capture%20d%E2%80%99e%CC%81cran%202024-01-18%20a%CC%80%2010.49.47.png',
    link: 'https://unitar.org/about/news-stories/news/unosats-empowerment-disaster-risk-management-navigating-geospatial-technologies-resilience-lao-pdr',
    category: 'News',
  },
];

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #e4eef6;
`;

const Section = styled.section`
  width: 100%;
  z-index: 0;
  position: relative;
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 550px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 600px;
  }

  .slick-slide {
    display: flex !important;
    height: 550px;

    @media (min-width: 768px) {
      height: 600px;
    }
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-dots {
    bottom: 25px;
    z-index: 10;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);

    @media (min-width: 768px) {
      font-size: 12px;
    }
  }

  .slick-dots li.slick-active button:before {
    color: rgba(255, 255, 255, 1);
  }
`;

const CarouselItemContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex !important;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  color: ${colors.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    padding: 0 60px;
  }
`;

// Structure fixe avec des hauteurs plus strictes
const ContentInner = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 40px 0 80px; // Augmentation du padding en bas pour éviter le chevauchement

  @media (min-width: 768px) {
    padding: 50px 0 100px; // Plus d'espace en bas sur desktop
  }
`;

// Section du haut avec hauteur fixe
const TopContent = styled.div`
  text-align: center;
  flex: 0 0 auto; // Ne pas étirer
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

// Section du milieu avec hauteur flexible mais limitée
const MiddleContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 200px; // Hauteur maximale sur mobile
  overflow: hidden;

  @media (min-width: 768px) {
    max-height: 250px; // Hauteur maximale sur desktop
  }
`;

// Section du bas avec position fixe
const BottomContent = styled.div`
  flex: 0 0 auto; // Ne pas étirer
  margin-top: auto; // Pousse vers le bas
  padding-top: 15px;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 30px;
  }
`;

const Category = styled.p`
  display: inline-block;
  font-size: 12px;
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: ${colors.WHITE};
  text-transform: uppercase;
  margin-bottom: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 4px;

  @media (min-width: 768px) {
    font-size: 14px;
    margin-bottom: 16px;
    padding: 6px 12px;
  }
`;

const Title = styled.h2`
  color: ${colors.WHITE};
  font-size: 24px;
  line-height: 1.2;
  font-weight: 900;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 1.2;
    -webkit-line-clamp: 2;
  }
`;

// Description avec troncature pour éviter le débordement
const Description = styled.p`
  color: ${colors.WHITE};
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
  width: 80%;
  max-height: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 1.6;
    width: 90%;
    -webkit-line-clamp: 12;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  padding: 10px 15px;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: ${colors.WHITE};
  background-color: ${(props) => (props.primary ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
  border: 2px solid ${colors.WHITE};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)'};
  }

  @media (min-width: 768px) {
    font-size: 15px;
    padding: 12px 20px;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 5;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (min-width: 768px) {
    width: 46px;
    height: 46px;
  }
`;

const PrevArrow = styled(ArrowButton)`
  left: 15px;

  @media (min-width: 768px) {
    left: 40px;
  }
`;

const NextArrow = styled(ArrowButton)`
  right: 15px;

  @media (min-width: 768px) {
    right: 40px;
  }
`;

const ArrowSVG = styled.svg`
  width: 18px;
  height: 18px;
  fill: none;
  stroke: white;
  stroke-width: 3;

  @media (min-width: 768px) {
    width: 22px;
    height: 22px;
  }
`;

const NewsAndStoriesFullWidth = () => {
  const [newsAndStories, setNewsAndStories] = useState(null);

  useEffect(() => {
    axios
      .get('/news_stories')
      .then((response) => {
        setNewsAndStories(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setNewsAndStories(defaultNewsAndStories);
      });
  }, []);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <PrevArrow onClick={onClick}>
        <ArrowSVG viewBox="0 0 24 24">
          <path d="M15 18l-6-6 6-6" />
        </ArrowSVG>
      </PrevArrow>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <NextArrow onClick={onClick}>
        <ArrowSVG viewBox="0 0 24 24">
          <path d="M9 18l6-6-6-6" />
        </ArrowSVG>
      </NextArrow>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <MainContainer>
      <Section>
        {newsAndStories && (
          <CarouselWrapper>
            <Slider {...settings}>
              {newsAndStories.map(({ title, description, category, link, image }, index) => (
                <CarouselItemContainer key={index} backgroundImage={`https://unosat.org${image}`}>
                  <ContentWrapper>
                    <ContentInner>
                      <TopContent>
                        <Category>{category.toUpperCase()}</Category>
                        <Title>{title}</Title>
                      </TopContent>

                      <MiddleContent>
                        <Description>{description}</Description>
                      </MiddleContent>

                      <BottomContent>
                        <ButtonWrapper>
                          <Button primary onClick={() => window.open(link, '_blank')}>
                            {category === 'Stories' ? 'SEE THIS STORY' : 'SEE NEWS'}
                          </Button>
                          <Button
                            onClick={() =>
                              window.open(
                                category === 'Stories' ? urls.UNITAR_STORIES : urls.UNITAR_NEWS,
                                '_blank',
                              )
                            }
                          >
                            {category === 'Stories' ? 'SEE ALL STORIES' : 'SEE ALL NEWS'}
                          </Button>
                        </ButtonWrapper>
                      </BottomContent>
                    </ContentInner>
                  </ContentWrapper>
                </CarouselItemContainer>
              ))}
            </Slider>
          </CarouselWrapper>
        )}
      </Section>
    </MainContainer>
  );
};

export default NewsAndStoriesFullWidth;
