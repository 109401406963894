import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from '@emotion/styled';
import ReactPaginate from 'react-paginate';
import flatten from 'lodash/flatten';
import { CaretLeft, CaretRight, RocketLaunch } from 'phosphor-react';

import ContentWrapper from '../../components/ContentWrapper';
import SectionTitle from '../../components/common/SectionTitle';
import LoadingDots from '../../components/common/LoadingDots';
import HomeHeader from '../../components/site-header/HomeHeader';
import { routes } from '../../constants/routes';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';

// Styles inspirés de HomePage
const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const SearchHero = styled.div`
  position: relative;
  padding: 60px 20px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${screenSizes.medium.min}) {
    padding: 80px 40px 100px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    padding: 100px 80px 120px;
  }
`;

const SearchTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin: 20px 0 40px 0;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.2;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 44px;
  }

  @media (min-width: ${screenSizes.large.min}) {
    font-size: 60px;
  }
`;

const SearchBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const SearchInput = styled.div`
  flex: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 4px 0 0 4px;
  overflow: hidden;
`;

const Input = styled.input`
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 10px 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    height: 70px;
    font-size: 18px;
  }
`;

const GoButton = styled.button`
  background-color: ${colors.BLUE};
  border: none;
  border-radius: 0 4px 4px 0;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  padding: 0 30px;
  height: 60px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    height: 70px;
    font-size: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    padding: 40px;
  }
`;

const ResultsFilters = styled.div`
  margin-bottom: 30px;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 25%;
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

const Filter = styled.div`
  width: 100%;
  background-color: ${colors.BLUE};
  padding: 15px;
  color: #fff;
  font-weight: 600;
  text-align: left;
  border-radius: 4px 4px 0 0;
`;

const Category = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid ${colors.BLUE};
  transition: all 0.3s ease;
  text-align: left;
  background-color: ${({ isSelected }) => (isSelected ? colors.DARK_BLUE : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#333')};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? colors.DARK_BLUE : '#f0f8ff')};
  }

  & span {
    color: ${({ isSelected }) => (isSelected ? '#fff' : colors.BLUE)};
    margin-right: 5px;
    font-weight: 700;
  }
`;

const Results = styled.div`
  flex: 1;
  text-align: left;
`;

const ResultsHeader = styled.div`
  margin-bottom: 30px;
`;

const ResultsTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.DARK_BLUE};
  margin-bottom: 20px;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 24px;
  }
`;

const SearchItem = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid ${colors.DARK_BLUE};
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(5px);
  }

  &:first-child {
    padding-top: 0;
  }
`;

const ItemTitle = styled.h3`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4em;
  margin-top: 0;
  margin-bottom: 10px;
  color: ${colors.BLUE};
`;

const ItemCategory = styled.div`
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #aeb3b5;
  font-size: 14px;
  letter-spacing: 1px;
`;

const ItemDescription = styled.div`
  line-height: 1.6;
  color: #333;
`;

const Pagination = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-top: 40px;
  margin: 0;

  li {
    list-style: none;
    margin: 0 10px;

    a {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: all 0.2s ease;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .pagination__link--disabled {
    color: #bababa;
    pointer-events: none;
  }

  .pagination__link--active {
    a {
      background-color: ${colors.BLUE};
      color: white;
    }
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;

    svg {
      margin-left: 10px;
    }
  }

  a {
    color: ${colors.BLUE};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${colors.DARK_BLUE};
      text-decoration: underline;
    }
  }
`;

// Nouveau composant pour wrapper le héros avec le dégradé comme dans HomePage
const HeroWrapper = styled.div`
  position: relative;
  background-color: ${colors.DARK_BLUE};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0.6) 100%
    );
    z-index: 1;
  }
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SearchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('q');
  const [selectedContent, setSelectedContent] = useState('ALL');
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [newsStories, setNewsStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newSearchValue, setNewSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 10;

  const handleOnSubmit = () => {
    if (!newSearchValue.trim()) return;
    history.push(routes.SEARCH + '?q=' + encodeURIComponent(newSearchValue));
    setCurrentPage(0);
  };

  const handleOnChange = (e) => {
    setNewSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit();
    }
  };

  useEffect(() => {
    if (searchValue) {
      setNewSearchValue(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!searchValue) {
      return;
    }

    setIsLoading(true);
    setCurrentPage(0);

    axios
      .post('/searchServer/', { search_term: searchValue })
      .then((response) => {
        setServices(
          response?.data?.services?.map((service) => ({ ...service, category: 'services' })) || [],
        );

        setProducts(
          response?.data?.products?.map((product) => ({ ...product, category: 'products' })) || [],
        );

        setNewsStories(
          response?.data?.news_stories?.map((news_story) => ({
            ...news_story,
            category: 'news',
          })) || [],
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [searchValue]);

  const getResults = () => {
    if (selectedContent === 'PRODUCTS') {
      return products;
    }

    if (selectedContent === 'SERVICES') {
      return services;
    }

    if (selectedContent === 'NEWS') {
      return newsStories;
    }

    return flatten([services, newsStories, products]);
  };

  const results = getResults();
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(results?.length / PER_PAGE);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    const isScrollBehaviorSupported = 'scrollBehavior' in document.documentElement.style;

    if (isScrollBehaviorSupported) {
      document.getElementById('results').scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleOnItemClick = (result) => () => {
    if (result.category === 'products') {
      history.push({
        pathname: `/products/${result.id}`,
      });
    }

    if (result.category === 'services') {
      history.push(routes.SERVICES, { selectedService: result.title });
    }

    if (result.category === 'news') {
      window.open(result?.link, '_blank');
    }
  };

  const currentPageData = results?.slice(offset, offset + PER_PAGE).map((result, index) => (
    <SearchItem
      key={`${result.category}-${result.id || index}`}
      onClick={handleOnItemClick(result)}
    >
      <ItemTitle>{result?.title}</ItemTitle>
      <ItemCategory>{result?.category}</ItemCategory>
      <ItemDescription>{result?.description}</ItemDescription>
    </SearchItem>
  ));

  return (
    <MainContainer>
      <HomeHeader />
      <HeroWrapper>
        <TextContainer>
          <SearchHero>
            <SearchTitle>SEARCH UNOSAT</SearchTitle>
            <SearchBox>
              <SearchInput>
                <Input
                  type="text"
                  id="text-search"
                  placeholder="What are you looking for?"
                  value={newSearchValue}
                  onChange={handleOnChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchInput>
              <GoButton onClick={handleOnSubmit}>SEARCH</GoButton>
            </SearchBox>
          </SearchHero>
        </TextContainer>
      </HeroWrapper>

      <ContentWrapper id="results">
        {searchValue && (
          <ResultsHeader>
            <SectionTitle text="SEARCH RESULTS" hasLine />
            <ResultsTitle>Results found for "{searchValue}"</ResultsTitle>
          </ResultsHeader>
        )}

        {isLoading ? (
          <Loading>
            <LoadingDots />
          </Loading>
        ) : (
          searchValue && (
            <Content>
              <ResultsFilters>
                <Filter>FILTER BY CONTENT TYPE:</Filter>
                <div>
                  <Category
                    isSelected={selectedContent === 'ALL'}
                    onClick={() => setSelectedContent('ALL')}
                  >
                    ALL <span>{results?.length || 0}</span>
                  </Category>
                  <Category
                    isSelected={selectedContent === 'SERVICES'}
                    onClick={() => setSelectedContent('SERVICES')}
                  >
                    SERVICES <span>{services?.length || 0}</span>
                  </Category>
                  <Category
                    isSelected={selectedContent === 'PRODUCTS'}
                    onClick={() => setSelectedContent('PRODUCTS')}
                  >
                    PRODUCTS <span>{products?.length || 0}</span>
                  </Category>
                  <Category
                    isSelected={selectedContent === 'NEWS'}
                    onClick={() => setSelectedContent('NEWS')}
                  >
                    NEWS & STORIES <span>{newsStories?.length || 0}</span>
                  </Category>
                </div>
              </ResultsFilters>

              <Results>
                {results.length > 0 ? (
                  <>
                    <div>{currentPageData}</div>
                    {pageCount > 1 && (
                      <Pagination
                        previousLabel={<CaretLeft size={20} />}
                        nextLabel={<CaretRight size={20} />}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={currentPage}
                        previousLinkClassName={'pagination__link'}
                        nextLinkClassName={'pagination__link'}
                        disabledClassName={'pagination__link--disabled'}
                        activeClassName={'pagination__link--active'}
                      />
                    )}
                  </>
                ) : (
                  <NoResults>
                    <div>
                      No results found <RocketLaunch size={24} />
                    </div>
                    <a href="https://www.unitar.org/" target="_blank" rel="noopener noreferrer">
                      Click to find more information on UNITAR.org
                    </a>
                  </NoResults>
                )}
              </Results>
            </Content>
          )
        )}
      </ContentWrapper>
    </MainContainer>
  );
};

export default SearchPage;
