import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { parseISO, format as formatDate, isValid } from 'date-fns';
import fallbackImage from '../../../assets/map-photo.png';
import Button from '../../../components/common/Button';
import DownloadLinks from './DownloadLinks';
import { colors } from '../../../constants/colors';
import screenSizes from '../../../constants/screenSizes';
import { routes } from '../../../constants/routes';
import { urls } from '../../../constants/urls';

const PageWrapper = styled.div`
  padding: 0 4px;
  @media (min-width: ${screenSizes.large.min}) {
    padding: 0 50px;
  }
`;

const GoBackButton = styled.div`
  cursor: pointer;
  margin-top: 80px;
  border-radius: 20px;
  width: fit-content;
  padding: 6px 12px;
  color: white;
  font-weight: 800;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  background-color: rgba(119, 139, 159, 0.7);

  @media (max-width: ${screenSizes.medium.max}) {
    padding: 6px 10px;
    font-size: 24px;
    background-color: transparent;
    color: rgba(119, 139, 159, 0.7);
    margin-left: -10px;
    margin-top: 60px;
  }

  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 140px;
    font-size: 13px;
  }
`;

const ButtonContent = styled.span`
  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const ArrowIcon = styled.span`
  display: none;
  font-size: 28px;
  font-weight: 300;
  color: rgba(72, 72, 72, 1);
  @media (max-width: ${screenSizes.medium.max}) {
    display: inline;
  }
`;

const DateUnderButton = styled.div`
  margin-top: 12px;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  color: rgba(82, 94, 104, 1);
  font-weight: 700;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 36px;
    margin-left: 4px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (min-width: ${screenSizes.large.min}) {
    flex-direction: row;
    margin-top: 28px;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    width: 30%;
    margin-left: 72px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
`;

const TitleWrapper = styled.h3`
  font-size: 25px;
  color: rgba(82, 94, 104, 1);
  margin: 0 0 16px 0;
  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 36px;
    margin: 0 0 24px 0;
  }
`;

const StrongPart = styled.span`
  font-weight: 900;
  margin-right: 5px;
`;

const RestPart = styled.span`
  font-weight: 700;
`;

const Title = ({ title }) => {
  if (!title) return null;

  const parts = title.split(' - ');
  const firstPart = parts[0] || '';
  const commaIndex = firstPart.indexOf(',');

  if (commaIndex === -1) {
    return (
      <TitleWrapper>
        <StrongPart>{firstPart}</StrongPart>
      </TitleWrapper>
    );
  }

  const strongPart = firstPart.substring(0, commaIndex);
  const restPart = firstPart.substring(commaIndex);

  return (
    <TitleWrapper>
      <StrongPart>{strongPart}</StrongPart>
      {restPart && <RestPart>{restPart}</RestPart>}
    </TitleWrapper>
  );
};

const MainContent = styled.div`
  font-size: 16px;
  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  width: 100%;
  margin-top: 30px;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 60px;
  }
`;

const DescriptionSection = styled.div`
  margin-bottom: 20px;
`;

const DescriptionLine = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  font-weight: 600;
  padding-bottom: 12px;
  color: rgba(82, 94, 104, 1);
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSizes.medium.min}) {
    font-size: 16px;
    line-height: 23px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const BoldLabel = styled.span`
  font-weight: 800;
  margin-bottom: 4px;
  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 22%;
    text-align: left;
    margin-bottom: 0;
  }
`;

const ContentPart = styled.div`
  overflow-wrap: break-word;

  @media (min-width: ${screenSizes.medium.min}) {
    flex: 0 0 78%;
    text-align: left;
  }
`;

const FullLineContent = styled.span`
  overflow-wrap: break-word;
  width: 100%;
  text-align: left;
  font-weight: 600;
`;

const ObservationList = styled.ul`
  margin: 10px 0 0 0;
  padding-left: 20px;
  list-style-type: disc;

  li {
    margin-bottom: 12px;
    padding-left: 5px;
    line-height: 1.4;
  }
`;

const SectionHeading = styled.div`
  width: 100%;
  font-weight: 800;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: rgba(82, 94, 104, 1);
`;

// Fonction utilitaire pour formater les dates de manière sécurisée
const formatDateSafely = (dateString, formatPattern) => {
  if (!dateString) return '';

  try {
    const parsedDate = parseISO(dateString);
    if (!isValid(parsedDate)) {
      return 'Invalid date';
    }
    return formatDate(parsedDate, formatPattern);
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

const DescriptionContent = ({ description }) => {
  if (!description) return null;

  // Fonction pour parser la description
  const parseDescription = (text) => {
    const cleanedText = text.replace(/\u00a0/g, ' ').replace(/\r\n/g, '\n');
    const lines = cleanedText
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    const hasStatus = lines.some((line) => line.toLowerCase().startsWith('status:'));
    const hasFurtherAction = lines.some((line) => line.toLowerCase().startsWith('further action'));

    // Format non structuré (paragraphes)
    if (!hasStatus && !hasFurtherAction) {
      const paragraphs = cleanedText.split(/\n\s*\n/).map((para) => para.trim());
      return {
        format: 'paragraphs',
        content: paragraphs.map((para) => ({
          text: para,
          isNote:
            para.toLowerCase().includes('note:') || para.toLowerCase().includes('please send'),
        })),
      };
    }

    // Format structuré
    let status = null;
    let furtherAction = null;
    const observations = [];

    lines.forEach((line) => {
      if (line.toLowerCase().startsWith('status:')) {
        status = line.split(':').slice(1).join(':').trim();
      } else if (line.toLowerCase().startsWith('further action')) {
        furtherAction = line.split(':').slice(1).join(':').trim();
      } else if (line && !line.toLowerCase().includes('further action')) {
        observations.push(line.replace(/;$/g, '').trim());
      }
    });

    return {
      format: 'structured',
      status,
      furtherAction,
      observations,
    };
  };

  const parsedData = parseDescription(description);

  // Styles pour DescriptionContent
  const DescriptionWrapper = styled.div`
    width: 100%;
    margin-top: 30px;
    @media (min-width: ${screenSizes.medium.min}) {
      margin-top: 60px;
    }
  `;

  const StructuredRow = styled.div`
    margin-bottom: 20px;
  `;

  const Label = styled.div`
    font-weight: 800;
    color: rgba(82, 94, 104, 1);
    margin-bottom: 8px;
  `;

  const Content = styled.div`
    font-weight: 600;
    color: rgba(82, 94, 104, 1);
    word-wrap: break-word;
  `;

  const ObservationParagraph = styled.p`
    margin-bottom: 10px;
    word-wrap: break-word;
  `;

  const Paragraph = styled.p`
    margin-bottom: 16px;
    font-weight: ${(props) => (props.isNote ? '600' : '500')};
    color: rgba(82, 94, 104, 1);
    ${(props) =>
      props.isNote &&
      `
    border-left: 3px solid rgba(82, 94, 104, 0.6);
    padding-left: 12px;
  `}
  `;

  // Rendu selon le format
  if (parsedData.format === 'structured') {
    return (
      <DescriptionWrapper>
        {parsedData.status && (
          <StructuredRow>
            <Label>Status:</Label>
            <Content>
              {parsedData.status}
              {parsedData.observations.length > 0 && (
                <div>
                  {parsedData.observations.map((obs, index) => (
                    <ObservationParagraph key={index}>{obs}</ObservationParagraph>
                  ))}
                </div>
              )}
            </Content>
          </StructuredRow>
        )}
        {parsedData.furtherAction && (
          <StructuredRow>
            <Label>Further action(s):</Label>
            <Content>{parsedData.furtherAction}</Content>
          </StructuredRow>
        )}
      </DescriptionWrapper>
    );
  }

  // Format paragraphes
  return (
    <DescriptionWrapper>
      {parsedData.content.map((para, index) => (
        <Paragraph key={index} isNote={para.isNote}>
          {para.text}
        </Paragraph>
      ))}
    </DescriptionWrapper>
  );
};

const ImageWrapper = styled.div`
  border-radius: 10px;
  margin-top: 20px;
  min-height: 10vh;
  overflow: hidden;
  display: none;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 40px;
    height: 50vh;
    display: block;
  }
`;

const ImageWrapperMobile = styled.div`
  border-radius: 10px;
  margin-top: 20px;
  min-height: 10vh;
  overflow: hidden;
  display: block;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-top: 40px;
    display: none;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const ProductInfo = styled.div`
  margin-bottom: 24px;
  text-align: left;

  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
  @media (min-width: ${screenSizes.medium.min}) {
    flex-direction: row;
    align-items: baseline;
  }
`;

const InfoLabel = styled.span`
  font-weight: 800;
  color: #333;
  margin-bottom: 4px;
  @media (min-width: ${screenSizes.medium.min}) {
    margin-right: 8px;
    flex-shrink: 0;
    min-width: 140px;
    margin-bottom: 0;
  }
`;

const InfoValue = styled.span`
  font-weight: 600;
  text-align: left;
`;

const MobileButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  @media (min-width: ${screenSizes.medium.min}) {
    display: none;
  }
`;

const DesktopButtonsWrapper = styled.div`
  display: none;

  @media (min-width: ${screenSizes.medium.min}) {
    display: block;
    margin-top: 100px;
  }
`;

const FeedbackItem = styled.div`
  display: flex;
  transition: all 0.3s ease;
  background: rgba(119, 139, 159, 0.7);
  align-items: center;
  border-radius: 20px;
  height: 28px;
  padding: 0 20px;
  width: fit-content;
  text-align: center;
  margin-top: 16px;

  &:hover {
    background: ${colors.BLUE};
  }
`;

const FeedbackText = styled.a`
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
`;

const RightColumnContent = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const TopRightContent = styled.div``;

const BottomRightContent = styled.div`
  @media (min-width: ${screenSizes.large.min}) {
    margin-top: auto;
    max-height: 50vh;
    overflow-y: auto;
  }
`;

const Sources = styled.div`
  line-height: 16px;
  padding-left: 15px;
  font-size: 11px;
  font-weight: 700;

  @media (max-width: ${screenSizes.medium.max}) {
    display: none;
  }
`;

const processSourceSection = (sources) => {
  if (!sources) return [];

  const lines = sources.split(/\r\n|\n/).filter((line) => line.trim().length > 0);
  const processedSources = [];
  let currentSection = null;
  let currentItems = [];

  lines.forEach((line) => {
    // Si c'est un titre de section comme "(1) Satellite Image"
    if (line.match(/^\(\d+\)/) || line.match(/^Analysis:/) || line.match(/^Production:/)) {
      // Enregistrer la section précédente si elle existe
      if (currentSection) {
        processedSources.push({
          section: currentSection,
          items: [...currentItems],
        });
      }

      // Commencer une nouvelle section
      currentSection = line;
      currentItems = [];
    }
    // Sinon c'est un élément de la section en cours
    else if (currentSection) {
      // Pour gérer les lignes qui ont un ":"
      if (line.includes(':')) {
        const [key, value] = line.split(':').map((part) => part.trim());
        currentItems.push({
          key: `${key}:`,
          value: value,
        });
      } else {
        currentItems.push({
          text: line.replace(/;$/, ''), // Supprimer le point-virgule à la fin
        });
      }
    }
  });

  // Ajouter la dernière section
  if (currentSection && currentItems.length > 0) {
    processedSources.push({
      section: currentSection,
      items: [...currentItems],
    });
  }

  return processedSources;
};

const ProductDetailsLarge = ({ productData = {}, lat, lon }) => {
  const history = useHistory();
  const {
    title,
    description,
    sources,
    id,
    created_at,
    glide,
    image_file,
    wmap_link,
    gdp_link,
    shp_link,
    kml_link,
    wms_link,
    pdf_name,
    excel_table,
  } = productData || {};

  const handleContactUsClick = () => {
    history.push(routes.CONTACT);
  };

  const goBack = () => {
    history.goBack();
  };

  // Traiter les sources
  const processedSources = processSourceSection(sources);

  // Formater la date de manière sécurisée
  const formattedDate = formatDateSafely(created_at, 'dd MMM yyyy');

  return (
    <PageWrapper>
      <GoBackButton onClick={goBack}>
        <ArrowIcon>&#8249;</ArrowIcon>
        <ButtonContent>BACK TO MAP</ButtonContent>
      </GoBackButton>
      <DateUnderButton>{formattedDate}</DateUnderButton>
      <ContentWrapper>
        <LeftColumn>
          <Title title={title} />
          <ImageWrapperMobile>
            <StyledImage src={image_file || fallbackImage} alt="Product" />
          </ImageWrapperMobile>
          <MainContent>
            <DescriptionContent description={description} />
            <DownloadLinks
              title={title}
              pdfName={pdf_name !== 'None' ? pdf_name : undefined}
              excel={excel_table && excel_table.includes('/None') ? undefined : excel_table}
              wmap={wmap_link}
              gdp={gdp_link}
              shp={shp_link}
              wms={wms_link}
              kml={kml_link}
              id={id}
            />
            <MobileButtonsWrapper>
              <Button onClick={handleContactUsClick} variant="white">
                CONTACT US
              </Button>
              <FeedbackItem>
                <FeedbackText href={urls.FEEDBACK} target="_blank" rel="noreferrer">
                  PRODUCT FEEDBACK
                </FeedbackText>
              </FeedbackItem>
            </MobileButtonsWrapper>
            <ImageWrapper>
              <StyledImage src={image_file || fallbackImage} alt="Product" />
            </ImageWrapper>
          </MainContent>
        </LeftColumn>
        <RightColumn>
          <RightColumnContent>
            <TopRightContent>
              <ProductInfo>
                <InfoItem>
                  <InfoLabel>PRODUCT ID:</InfoLabel>
                  <InfoValue>{id}</InfoValue>
                </InfoItem>
                <InfoItem>
                  <InfoLabel>PUBLISHED:</InfoLabel>
                  <InfoValue>{formattedDate}</InfoValue>
                </InfoItem>
                {glide && (
                  <InfoItem>
                    <InfoLabel>GLIDE:</InfoLabel>
                    <InfoValue>{glide}</InfoValue>
                  </InfoItem>
                )}
              </ProductInfo>
              <DesktopButtonsWrapper>
                <Button onClick={handleContactUsClick} variant="white">
                  CONTACT US
                </Button>
                <FeedbackItem>
                  <FeedbackText href={urls.FEEDBACK} target="_blank" rel="noreferrer">
                    PRODUCT FEEDBACK
                  </FeedbackText>
                </FeedbackItem>
              </DesktopButtonsWrapper>
            </TopRightContent>
            <BottomRightContent>
              {processedSources.length > 0 && (
                <Sources>
                  {processedSources.map((sourceSection, sectionIndex) => (
                    <div key={`section-${sectionIndex}`}>
                      <div style={{ fontWeight: '800', marginTop: '8px' }}>
                        {sourceSection.section}
                      </div>
                      {sourceSection.items.map((item, itemIndex) => (
                        <div
                          key={`item-${sectionIndex}-${itemIndex}`}
                          style={{ marginLeft: '8px' }}
                        >
                          {item.key ? (
                            <>
                              <span style={{ fontWeight: '700' }}>{item.key}</span> {item.value}
                            </>
                          ) : (
                            item.text
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </Sources>
              )}
            </BottomRightContent>
          </RightColumnContent>
        </RightColumn>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ProductDetailsLarge;
