import React from 'react';
import styled from '@emotion/styled';
import { MagnifyingGlass } from 'phosphor-react';
import { colors } from '../../constants/colors';
import screenSizes from '../../constants/screenSizes';

const SearchWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(144, 165, 185, 0.33);
  padding: 20px 0;
  transition: all 0.3s ease;
`;

const SearchForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 20px 0 0 20px;
  padding: 0 20px;
  font-size: 16px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.85);
  color: ${colors.BLACK};
  transition: all 0.3s ease;

  &::placeholder {
    color: rgba(28, 40, 51, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(28, 40, 51, 0.2);
  }

  @media (min-width: ${screenSizes.medium.min}) {
    height: 50px;
    font-size: 18px;
  }
`;

const SearchButton = styled.button`
  height: 40px;
  border: none;
  border-radius: 0 20px 20px 0;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 700;
  background-color: rgba(28, 40, 51, 0.85);
  color: ${colors.WHITE};
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(28, 40, 51, 0.8);
  }

  @media (min-width: ${screenSizes.medium.min}) {
    height: 50px;
    font-size: 18px;
  }
`;

const SearchComponent = ({ searchValue, setSearchValue, handleSearchSubmit }) => {
  // Gestion du formulaire proprement avec preventDefault
  const onFormSubmit = (e) => {
    e.preventDefault(); // Empêche le comportement par défaut du formulaire
    e.stopPropagation(); // Arrête la propagation de l'événement
    handleSearchSubmit();
  };

  return (
    <SearchWrapper>
      <SearchForm onSubmit={onFormSubmit} id="search-form" role="search">
        <SearchInput
          type="search"
          name="q"
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          aria-label="Search"
          autoComplete="off"
        />
        <SearchButton type="submit">
          <MagnifyingGlass size={24} color={colors.WHITE} weight="bold" />
        </SearchButton>
      </SearchForm>
    </SearchWrapper>
  );
};

export default SearchComponent;
